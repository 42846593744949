<template>
<v-app class="topmain">
  <v-container class="spacing-playground pa-0">

    <v-app-bar app elevate-on-scroll style="z-index:900;max-width:100vw;" class="px-md-16 px-0 w-100" >
      <div class="d-flex align-center">
        <v-toolbar-items class="w4start upopacity00" >Klavierlehrerin JULIA FABEL</v-toolbar-items>
      </div>

      <v-spacer></v-spacer>
      <scrollactive class="my-nav " active-class="active" :offset="60" :duration="800" bezier-easing-value=".5,0,.35,1" >

        <div class="d-none d-lg-flex">
          <v-btn v-for="(item, i) in items" :key="i" v-text="item.text" color="primary" text :href="'#' + item.href" class="scrollactive-item w4start" :class='"upopacity"+i' ></v-btn>
        </div>






      </scrollactive>

      <v-spacer></v-spacer>

      <v-menu bottom left class="d-flex d-lg-none ">
        <template v-slot:activator="{ on, attrs }">
          <v-btn light icon color="primary" v-bind="attrs" v-on="on" class="w4start upopacity00 mobmenu">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card class="mx-auto" width="300" tile>
          <v-list shaped>
            <v-subheader>MENU</v-subheader>
            <v-list-item-group v-model="selectedItem" >
              <scrollactive class="my-nav " active-class="active" :offset="40" :duration="800" bezier-easing-value=".5,0,.35,1" color="primary"  >
                  <a :href="'#' + item.href" class="scrollactive-item text-decoration-none" v-for="(item, i) in items" :key="i">
                <v-list-item >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>

                      <v-list-item-title v-text="item.text"></v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
                </a>
                <a href="#kontakte" class="scrollactive-item text-decoration-none">
                <v-list-item >
                  <v-list-item-icon>
                    <v-icon>mdi-card-account-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>

                      <v-list-item-title>Kontakte</v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
              </a>
              </scrollactive>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>


      <v-btn outlined color="secondary" text href="#kontakte" class="w4start upopacity6  scrollactive-item" >Kontakte</v-btn>

    </v-app-bar>

  </v-container>


  <v-main class="p0">
    <v-container class="pa-0" fluid>
      <MainPage />
    </v-container>
  </v-main>

  <v-bottom-navigation color="primary" text>
    <span class="pt-5 text-caption text-md-body-1">
    <v-icon color="secondary">mdi-copyright</v-icon> Julia Fabel 2009 - {{new Date().getFullYear()}} – All Rights Reserved</span>
 </v-bottom-navigation>
</v-app>
</template>

<script>
import MainPage from './components/MainPage';

export default {
  name: 'App',

  components: {
    MainPage,
  },

  data: () => ({
    selectedItem: 1,
    items: [{
        href: 'home',
        text: 'Startseite',
        icon: 'mdi-home'
      },
      {
        href: 'ubermich',
        text: 'Über mich',
        icon: 'mdi-account'
      },
      {
        href: 'leistungen',
        text: 'Leistungen',
        icon: 'mdi-newspaper-variant'
      },
      {
        href: 'bilder',
        text: 'Bilder & Video Gallery',
        icon: 'mdi-video'
      },
      {
        href: 'termine',
        text: 'Termine',
        icon: 'mdi-bell-alert'
      },
      {
        href: 'horproben',
        text: 'Hörproben',
        icon: 'mdi-music'
      },
    ],
  }),
};
</script>
<style>
  @font-face {
  font-family: 'RobotoSlab';
  font-style: normal;
  font-weight: 400;
  src: url('/static/RobotoSlab-Regular.ttf') format('truetype');
}




* {
  font-family: 'RobotoSlab';
}
$body-font-family: 'RobotoSlab';


.v-application--wrap {
  position: relative;
}

.v-main {
  padding: 0 !important;
}

.v-app-bar--is-scrolled {
  background: #fff !important
}

.v-app-bar--hide-shadow {
  background: transparent !important
}


.upopacity00 {
  animation: fullwhite .5s forwards;
  opacity: 0;
}
.upopacity0 {
  animation: fullwhite .5s forwards 0.1s;
  opacity: 0;
}
.upopacity1 {
  animation: fullwhite .5s forwards 0.2s;
  opacity: 0;
}
.upopacity2 {
  animation: fullwhite .5s forwards 0.3s;
  opacity: 0;
}
.upopacity3 {
  animation: fullwhite .5s forwards 0.4s;
  opacity: 0;
}
.upopacity4 {
  animation: fullwhite .5s forwards 0.5s;
  opacity: 0;
}
.upopacity5 {
  animation: fullwhite .5s forwards 0.6s;
  opacity: 0;
}
.upopacity6 {
  animation: fullwhite .5s forwards 0.7s;
  opacity: 0;
}
.mobmenu {
  display:none !important;
}

@media only screen
  and (min-device-width: 10px)
  and (max-device-width: 736px) {

    .upopacity6 {
      display:none;
    }
    .mobmenu {
      display:block !important;
    }
    .w4start.mobmenu {
      display:none !important;
    }

}


@keyframes fullwhite {
  0% {
    opacity: 0;
    transform: translate3d(0,40px,0)
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }

}

</style>
