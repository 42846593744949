import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: "#DAB441", // #E53935
        secondary: "#DA4167", // #FFCDD2
        accent: "#333333", // #3F51B5
      },
    },
  },
})
