<template>
<div class="main overflow-hidden" v-cloak>
  <vuetitle title="Julia Fabel Website"></vuetitle>

  <div v-bind:style="{ height: heighted }">

    <!-- <div class="juliaa" data-aos="zoom-out-left" data-aos-duration="2000"></div> -->
    <PianoKeyboard :fullscreen="$vuetify.breakpoint.mobile" class="pianokeyboard " v-bind:class="{ pianokeyboardaf: started , upopacitykey: ended }" height="200" :showKeys="showKeys" :showNotes="showNotes" :sustain="sustain" :whiteNoteColor="whiteNoteColor" :blackNoteColor="blackNoteColor" :startOctave="startOctave"
      :endOctave="endOctave" :allKeys="allKeys" />



    <v-container class="main mt-6 mt-md-16 position-relative" :class="{ opacity: !opa1 }" style="z-index:10;" id="home">
      <v-row class="pt-10 pt-md-16 justify-center">
        <v-col class="text-center d-inline-block" cols="12">
          <div class="d-inline-block">


 <h1 class="text-h2  text-md-h1" data-aos="fade-up" data-aos-delay="100">Julia Fabel</h1>
<h2 class="mt-0 text-h6 text-md-h5" data-aos="fade-up" data-aos-delay="200">RUSSISCHE KLAVIERLEHRERIN</h2>
 <h5 class="text--secondary" data-aos="fade-up" data-aos-delay="300">Herzlich willkommen auf meiner Webseite</h5>
          </div>
        </v-col>
        <v-col id="mainmusic" class="mainmusic text-center mt-10 mt-md-16 position-absolute" cols="11" sm="11" md="10">
<div v-if="dense == 0">
<div id="firstimg" class="firstimgcon" v-bind:class="{'d-none' : notstarted}">
<v-img class="firstimg" :src="thefirstimage"></v-img>
<div class="prelaodimg" style="visibility:hidden" :style="{ 'background-url': thefirstimage }"></div>
</div>

<div id="secimg" class="secimgcon" v-bind:class="{'d-none' : notstarted}">
<v-img class="secimg" :src="thesecimg"></v-img>
<div class="prelaodimg" style="visibility:hidden" :style="{ 'background-url': thesecimg }"></div>
</div>
</div>
<div v-else >
  <div id="firstimglan" class="firstimglancon" v-bind:class="{'d-none' : notstarted}">
  <v-img class="firstimglan" :src="thefirstimage"></v-img>
  <div class="prelaodimg" style="visibility:hidden" :style="{ 'background-url': thefirstimage }"></div>
  </div>
</div>



          <v-col
          class="startherebox blue-grey white--text rounded"
          x-large
          :loading="loading">

          <span class="first">Wir schätzen Ihre Privatsphäre</span> <br>

          <div class="mb-10">
          Indem Sie auf "Alle Cookies akzeptieren" klicken,
          stimmen Sie der Speicherung von Cookies auf Ihrem Gerät und der
          damit verbundenen Verarbeitung gemäß der <a class="" href="/datenschutzerklärung.pdf" target="_top">DSGVO</a> zu, </div>

	<div class="butts">
          <v-btn class="float-left" onclick="let new_window = open(location, '_self');new_window.close();return false;"> Exit </v-btn>
          <v-btn class="float-right green" id="enter" v-on:click="startfunc()"> Alle Cookies akzeptieren </v-btn>
	</div>
           </v-col>



        </v-col>



      </v-row>

    </v-container>

  </div>

  <div id="ubermich" class="grey lighten-5 pt-5">

    <v-container>
      <v-row class="pa-10 justify-space-between">
        <v-col md="4" cols="12" data-aos="fade-right" data-aos-delay="100">

          <Titles first="BIO" sec="GRAPHIE" />
          <p class="justifytext" style="word-spacing: -.07em;">
            Ausbildung zur staatlich geprüften Klavierlehrerin an der Musik-Hochschule in Rzhef / Russland, dann 25 Jahre Klavierlehrerin
            an der staatlichen Musik-Schule in Zapadnaja Dvina / Russland, seit 2009 privater Klavierunterricht hier im Raum Peißenberg, Weilheim (Obb) und Umgebung.
          </p>

        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" cols="12" data-aos="fade-left" data-aos-delay="100">

          <v-img src="/photos/self.jpg" lazy-src="/photos/self.jpg" aspect-ratio="1" class="grey lighten-2 rounded-lg">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>

        </v-col>
        <v-col md="4" cols="12" data-aos="fade-left" data-aos-delay="200">

          <h3 class="text-xl-h2 primary--text mb-2 mb-md-5 mt-0">Persönliche Angaben</h3>

          <v-simple-table class="secondary white--text" dark>
            <template>

              <tbody>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <td class="text-left">
                    Julia Fabel
                  </td>
                </tr>
                <tr>
                  <th class="text-left">
                    Phone
                  </th>
                  <td class="text-left">
                    0151 212 56054
                  </td>
                </tr>
                <tr>
                  <th class="text-left">
                    E-mail
                  </th>
                  <td class="text-left">
                    <a href="mailto:jfabel@julia-fabel.de">jfabel@julia-fabel.de</a>
                  </td>
                </tr>
                <tr>
                  <th class="text-left">
                    Adresse
                  </th>
                  <td class="text-left">
                    Kanalstr. 5b, 82362 Weilheim(Obb)
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-col>
      </v-row>
    </v-container>

  </div>

  <v-parallax src="/photos/piano.jpg" class="w-100"></v-parallax>


  <div id="leistungen" class="light lighten-5 pt-5">
    <v-container>
      <v-row class="pa-5 pa-md-10 justify-space-between">
        <v-col md="12" cols="12">
          <Titles first="Leistun" sec="gen"  data-aos="fade-right" data-aos-delay="100" />

          <v-timeline :dense="dense" class="my-16">

            <v-timeline-item
              small
            >
            <template v-slot:opposite>
        <span class="headline"  data-aos="zoom-in" :data-aos-delay="delay">1985</span>
        </template>
              <div class="py-4">
                <div  data-aos="fade-left" data-aos-delay="200">
                  Vor 35 Jahren begann meine Laufbahn als Klavierlehrerin für Kinder im Alter von 7 bis 18 Jahren.
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              small
            >
            <template v-slot:opposite>
        <span class="headline"  data-aos="zoom-in" :data-aos-delay="delay">2010</span>
        </template>
              <div class="py-4">
                <div  data-aos="fade-right" data-aos-delay="200">
                  Davon die letzten 14 Jahre als privater Klavierunterricht hier im Bereich Weilheim - Peißenberg.
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
              small
            >
            <template v-slot:opposite>
        <span class="headline font-weight-bold"  data-aos="zoom-in" :data-aos-delay="delay">2020</span>
        </template>
              <div  data-aos="fade-left" data-aos-delay="0">
2020 - 2022 habe ich wegen Corona teilweise auch Video-Klavier-Unterricht via WhatsApp Video gegeben. Meine Klavierschüler haben diese Möglichkeit gerne angenommen, aber 2023 konnten wir den normalen Face-to-Face Klavier-Unterricht wieder aufnehmen.
              </div>
            </v-timeline-item>
          </v-timeline>
<v-row class="mb-16">
<v-col cols="12" md="6">
  <v-card
    class="mx-auto" data-aos="zoom-in-up" light>

    <v-card-text class="justifytext">
      Für kleine Kinder ist eine Unterrichtsstunde in der Regel 30 Minuten lang, später dann 45 Minuten, in Ausnahmefällen auch 60 Minuten. Ich freue mich auch immer wieder über Erwachsene „Schüler“, die ihr Klavierspiel wieder auffrischen
      wollen, oder auch ganz von Null anfangen, um z.B. zusammen mit ihren Kindern zu musizieren.    </v-card-text>

  </v-card>
</v-col>
<v-col cols="12" md="6">
  <v-card
    class="mx-auto" data-aos="zoom-in-up" light>

    <v-card-text class="justifytext" style="letter-spacing: 0em;">
    Einmal jährlich geben allen meinen Klavierschüler ein Vorspielkonzert, entweder an einem der letzten Samstage vor den großen Sommerferien, oder an einem der letzten Samstage vor den Weihnachtsferien. Alle Eltern und Verwandte sind sehr herzlich dazu eingeladen.
    </v-card-text>

  </v-card>
</v-col>
</v-row>

          <v-alert class="blue-grey" style="color:white;" colored-border color="secondary" elevation="2" data-aos="fade-left">
Rufen Sie jetzt <u>0151 - 212 56054</u> and vereinbaren Sie eine Probestunde !
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <div id="bilder" class="grey lighten-5 pt-5">
    <v-container>
      <v-row class="pa-5 pa-md-10 justify-center">

          <h2 class="textgradient font-weight-bold mb-10 mb-md-5 text-md-h3 text-h4 text-uppercase text-center d-inline-block" data-aos="fade-right" data-aos-delay="100">BILDER & VIDEO GALLERY</h2>

          <v-col cols="12" class="w-100"></v-col>

             <VueGallery :images="images" :index="index" @close="index = null"></VueGallery>
             <v-col
               cols="12" md="4"
               class="image justify-center text-center mx-0 rounded-lg position-relative"
               :id= '"imageno"+imageIndex'
               data-aos="zoom-in-up"
               :data-aos-duration='(imageIndex+5)+"00"'
               v-for="(image, imageIndex) in images"
               :key="imageIndex"
               @click="index = imageIndex"
               :style="{ backgroundImage: 'url(' + image + ')',     'background-size': '93%'  , 'min-height': '250px', }"
             >
             <div id="upline" v-if="imageIndex == 0 && dense == 0" class="upline" data-aos="fade-right" data-aos-delay="200" data-aos-duration="3000"></div>
              <div v-if="imageIndex == 5 && dense == 0" class="downline" data-aos="fade-left" data-aos-delay="200" data-aos-duration="3000" data-aos-anchor="#upline"></div>
           </v-col>

<v-col cols="12" class="w-100"></v-col>

        <v-col class="rounded-video overflow-hidden" md="6" cols="12" data-aos="fade-right" data-aos-delay="100">
          <video-player class="vjs-16-9 video-player-box vjs-big-play-centered" ref="videoPlayer" :options="playerOptions">
          </video-player>
        </v-col>
        <v-col class="rounded-video overflow-hidden" md="6" cols="12" data-aos="fade-left" data-aos-delay="100">
          <video-player class="vjs-4-3 video-player-box vjs-big-play-centered" ref="videoPlayer" :options="playerOptions2">
          </video-player>
        </v-col>
        <v-col class="rounded-video overflow-hidden" md="6" cols="12" data-aos="fade-right" data-aos-delay="100">
          <video-player class="vjs-4-3 video-player-box vjs-big-play-centered" ref="videoPlayer" :options="playerOptions3">
          </video-player>
        </v-col>
        <v-col class="rounded-video overflow-hidden" md="6" cols="12" data-aos="fade-left" data-aos-delay="100">
          <video-player class="vjs-4-3 video-player-box vjs-big-play-centered" ref="videoPlayer" :options="playerOptions4">
          </video-player>
        </v-col>
      </v-row>

    </v-container>
  </div>

  <div id="termine" class="light lighten-5 pt-5">
    <v-container>
      <v-row class="pa-5 pa-md-10 justify-center">
        <v-col md="12" cols="12" data-aos="fade-right">
          <Titles first="Ter" sec="mine" />
          <p class="pt-4 mx-2" style="letter-spacing: -0.005em;">
		Der Termin für das nächste Vorspiel-Konzert wird rechtzeitig bekannt gegeben, voraussichtlich wieder im Rainer-Endisch-Saal in 86971 Peiting-Herzogsägmühle, Kapellenfeld.
          </p>
        </v-col>

        <v-col md="8" cols="12" data-aos="fade-right" data-aos-delay="100">
          <v-img src="/photos/termine.png" lazy-src="/photos/termine.png" :aspect-ratio="18/11"
            class="grey lighten-2 rounded-lg">
          </v-img>
        </v-col>

      </v-row>
    </v-container>
  </div>
<v-parallax :height="height" :src="playingimg"></v-parallax>

  <div id="horproben" class="grey lighten-5 pt-5">
    <v-container>
      <v-row class="pa-5 pa-md-10 justify-space-between">
        <v-col cols="12" md="6" data-aos="fade-right">
          <Titles first="Hör" sec="proben" />



          <v-hover v-slot="{ hover }">

            <v-img cover :aspect-ratio="10/9" src="/photos/cd-cover-front.png">
              <v-slide-x-transition>
                <div v-if="hover" style="height: 100%;">
                  <v-img cover :aspect-ratio="10/9" src="/photos/cd-cover-back.png"> </v-img>
                </div>
              </v-slide-x-transition>
            </v-img>

          </v-hover>



        </v-col>
        <v-col data-aos="fade-left" data-aos-delay="100">

          <aplayer :music='musiclist[0]' :list='musiclist' />


        </v-col>
      </v-row>
    </v-container>
  </div>

  <div id="kontakte" class="light lighten-5 pt-5" style="max-width: 100vw !important;">
    <v-container>
      <v-row class="pa-5 pa-md-10 justify-space-between">
        <v-col cols="12" md="6" data-aos="fade-right">
          <Titles first="Kontakt" sec="daten" />

          <v-list  subheader
      two-line>
              <v-list-item>
                <v-list-item-avatar>
                    <v-icon color="secondary">mdi-phone-classic</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title  class="wrapit">0881 927 91903</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="secondary">mdi-cellphone-android</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title  class="wrapit">0151 212 56054</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="secondary">mdi-map-marker</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="wrapit">Kanalstr. 5b, 82362 Weilheim(Obb)</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="secondary">mdi-whatsapp</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="wrapit">@WhatsApp 0151 212 56054 </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn small href="https://wa.me/?phone=4915121256054" outlined color=" lighten-1" target="_blank" icon>
                    <v-icon small color=" lighten-1">mdi-send</v-icon>
                  </v-btn>
      </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="secondary">mdi-email</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="wrapit"><a class="v-list-item__title" href="mailto:jfabel@julia-fabel.de">jfabel@julia-fabel.de</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="secondary">mdi-web</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="wrapit">www.julia-fabel.de</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="secondary">mdi-text-box-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="wrapit"><a target="_blank" class="v-list-item__title" href="/datenschutzerklärung.pdf?a=kontakte">Datenschutzerklärung</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

          </v-list>

        </v-col>
        <v-col cols="12" md="6" data-aos="fade-left" style="position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;">
          <iframe style="position: absolute;top:0;left: 0;width: 100%;height: 100%;" tabindex="0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2677.6791993516636!2d11.131141915537514!3d47.84580787941609!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479db51fc6fdcce7%3A0xa387b348338960ff!2sKanalstra%C3%9Fe%205b%2C%2082362%20Weilheim%20in%20Oberbayern%2C%20Germany!5e0!3m2!1sen!2s!4v1595322782380!5m2!1sen!2s"
            frameborder="0" allowfullscreen=""></iframe>

        </v-col>
      </v-row>
    </v-container>
  </div>

</div>
</template>

<script>
import PianoKeyboard from './PianoKeyboard'
import Titles from './title'
import Aplayer from 'vue-aplayer'
import vuetitle from './pagetitle.vue';
import VueGallery from 'vue-gallery';
//import VueKinesis from "vue-kinesis"
//import { KinesisContainer, KinesisElement} from 'vue-kinesis'
import $ from 'jquery'
import gsap from "gsap";



export default {
  props: {
    msg: String
  },
  data: function() {
    return {
      started: false,
      notstarted: true,
      ended: false,
      musiclist: [{
          theme: '#da4167',
          title: 'POLONAISE',
          artist: 'Michael Ogiński',
          src: '/music/01-Titel-01-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'WALZER IN H-MOLL',
          artist: 'Franz Schubert',
          src: '/music/02-Titel-02-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'WALZER IN E-MOLL',
          artist: 'Alexander Gribojedov',
          src: '/music/03-Titel-03-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'WALZER AUS "MASKERADE"',
          artist: 'Aram Khachaturian',
          src: '/music/04-Titel-04-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'WALZER IN A-MOLL',
          artist: 'Frédéric Chopin',
          src: '/music/05-Titel-05-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'TÜRKISCHER MARSCH',
          artist: 'Wolfgang A. Mozart',
          src: '/music/06-Titel-06-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'FÜR ELISE IN A-MOLL',
          artist: 'Ludwig van Beethoven',
          src: '/music/07-Titel-07-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'WALZER IN GIS-MOLL',
          artist: 'Frédéric Chopin',
          src: '/music/08-Titel-08-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'MELODIE',
          artist: 'Raimonds Pauls',
          src: '/music/09-Titel-09-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'MEMORIES AUS CATS',
          artist: 'Andrew Lloyd Webber',
          src: '/music/10-Titel-10-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'BALLADE FÜR ADELINE',
          artist: 'Paul de Senneville',
          src: '/music/11-Titel-11-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'FIELD FLOWERS',
          artist: 'Raimonds Pauls',
          src: '/music/12-Titel-12-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
        {
          theme: '#da4167',
          title: 'YOU ARE MY EVERYTHING',
          artist: 'Santa Esmeralda',
          src: '/music/13-Titel-13-1.m4a',
          pic: '/photos/cd-cover-front.png',
        },
      ],
      date: '2020-12',
      isMobile: false,
      whiteNoteColor: '#e4e4ec',
      blackNoteColor: '#e4e4ec',
      showKeys: false,
      showNotes: false,
      startOctave: 2,
      sustain: false,
      allKeys: ['`', `1`, '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.'],
      playerOptions: {
        fluid: true,
        aspectRatio: '16:9',
        poster: "/photos/2.jpg" ,
        sources: [{
          autoplay: true,
          type: "video/mp4",
          src: "/videos/Elena.mp4"
        }],
      },
      playerOptions2: {
        fluid: true,
        poster: "/photos/1.jpg" ,
        sources: [{
          autoplay: true,
          type: "video/mp4",
          src: "/videos/Elena-Kästner.mp4"
        }],
      },
      playerOptions3: {
        fluid: true,
        poster: "/photos/3.jpg" ,
        sources: [{
          autoplay: true,
          type: "video/mp4",
          src: "/videos/Thessa-Hellmann.mp4"
        }],
      },
      playerOptions4: {
        fluid: true,
        sources: [{
          autoplay: true,
          type: "video/mp4",
          src: "/videos/Julia.mp4#t=.1"
        }],
      },
      images: [
          '/photos/gall3.jpg',
          '/photos/gall2.jpg',
          '/photos/gall1.jpg',
          '/photos/gall4.jpg',
          '/photos/gall5.jpg',
          '/photos/gall6.jpg',
        ],
        index: null,
        audio: new Audio('/music/startersong.mp3'),
        loading: true
        //thefirstimage: "thefirstimage",
    }
  },

  components: {
    PianoKeyboard,
    Aplayer,
    Titles,
    vuetitle,
    VueGallery,


  },
  computed: {
  endOctave: function () {
    if(this.$vuetify.breakpoint.name == "xs"){
      return 2;
    }else {
      return 6;
    }
  },
  dense () {
    if(this.$vuetify.breakpoint.name == "xs"){
      return true;
    }else {
      return false;
    }
},
delay () {
  if(this.$vuetify.breakpoint.name == "xs"){
    return "0";
  }else {
    return "250";
  }
},
height () {
  if(this.$vuetify.breakpoint.name == "xs"){
    return "200";
  }else {
    return "400";
  }
},
heighted () {
  if(this.$vuetify.breakpoint.name == "xs"){
    return "96vh";
  }else {
    return "92vh";
  }
},
playingimg () {
  if(this.$vuetify.breakpoint.name == "xs"){
    return "/photos/playinglan.jpg";
  }else {
    return "/photos/playing.jpg";
  }
},
thefirstimage () {
  if(this.$vuetify.breakpoint.name == "xs"){
    return "/photos/firstimglan.png";
  }else {
    return "/photos/firstimg.png";
  }
},
thesecimg () {
  if(this.$vuetify.breakpoint.name == "xs"){
    return "/photos/secimg.jpg";
  }else {
    return "/photos/secimg.png";
  }
},



},
  methods: {
    getTitleDateFormat() {
      return "Dezember"
    },
    startfunc() {

  $('.startherebox').addClass('d-none');


  gsap.to(".firstimg", {
    duration: 7,
    opacity: 1,
    left: "45rem",

  });

    gsap.to(".secimg", {
    duration: 7,
    opacity: 1,
    right: "45rem",

  });

	this.started = true;

        this.notstarted = false;

        setTimeout(() => { this.audio.play(); } , 900);
        setTimeout(() => { this.ended = true; this.started = false;  this.$emit('showMenu'); } , 7000);

        setTimeout(() => {
        $('.w4start').removeClass('w4start');
        $('body').addClass('scrollabled');
      } , 7600);



    }
  },
mounted: function() {




  const image = new Image();
  image.src = this.thefirstimage;

  const image2 = new Image();
  image2.src = this.thesecimg;

  this.audio.addEventListener('canplaythrough', () => {

      setTimeout(() => {
          this.loading = false;
        } , 500);

  })

  this.audio.load();
  },
created: function() {
document.fonts.ready.then(function () {
         setTimeout(() => {
$('#home').animate({opacity: 1}, 1050);

      } , 500);

});


setTimeout(function() {
	document.getElementById('wait').style.display = 'none'
}, 1500);


},
beforeCreate: function() {

}
}




</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#wait {
	position: absolute;
	width: 100%;
	height: 100%;
	background: white;
	z-index: 9999;
	display: block;
}
#home {

}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
* {

}
body {
  overflow-y: hidden;
  height: 100vh;
}
.scrollabled {
  overflow-y: visible !important;
}

.pianokeyboard {
  position: absolute;
  height: 102vh;
  top: 0;
  z-index: 0;
}

.pianokeyboard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0));
  z-index: 20;
}
@keyframes fullwhite {
  0% {
    opacity: 0;
    transform: translate3d(0,40px,0)
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }

}

.text---primary {
  border-bottom: 1px solid #dab441 !important;
}

.text---secondary {
  border-bottom: 1px solid #da4167 !important;
}

p {
  line-height: 1.8em;
}

.w-100 {
  width: 100%;
}

.video-js {
  background-color: #fff !important;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-video {
  border-radius: 3em;
}

.border-secondary {
  border: 1px solid #dab441 !important;
}
.wrapit {
  white-space: normal !important;
}
.justifytext {
  text-align: justify;
}
.textgradient {
  text-transform: uppercase;
background: linear-gradient(to right, #dab441 0%, #da4167 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}
.image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
#imageno0,#imageno5 {
  background-position: center -100px !important;
}

.mainmusic .aplayer-pic {
  width:130px !important;
  height:130px !important;
}
.mainmusic .aplayer-info {
  height:110px !important;
}
.mainmusic .aplayer-title {
  font-size: 20px !important
}

.mainmusic .aplayer-author {
  font-size: 18px !important
}
.mainmusic .aplayer {
  -webkit-box-shadow: 0 8px 8px 0 rgba(0,0,0,.09), 0 10px 14px 0 rgba(0,0,0,.2);
    box-shadow: 0 8px 8px 0 rgba(0,0,0,.09), 0 10px 14px 0 rgba(0,0,0,.2);
}
.startherebox {
height: inherit !important;
padding: 10px 15px 15px 15px !important;
  background-color: rgba(0,0,0,.8) !important;
 transition: all 5s ease;
     width: 60%;
     left: 50%;
    position: relative;
    transform: translateX(-50%);
    line-height: 1.5em;
    font-size: 1.2vw;
	display:block;
}
.startherebox .first {
	font-size: 1.8vw;
}
.startherebox a {
    font-size: 1vw;
}
.startherebox a:nth-child(1) {
    font-size: 1.2vw;
    color: #3F51B5;
}
.butts {
	position: absolute;
	display: block;
	bottom: 5%;
	width: 95%;
}
.butts button:nth-child(1){
	background-color: #EF5350 !important;
}
.butts button:nth-child(2){
	background-color: #66BB6A !important;
}

.butts .green {
	padding: 0 32px !important;
}
.butts button {
	color: white !important;
}
.piano-keyboard {
  opacity: .1;
}

.firstimgcon , .secimgcon {
  position: relative;
  height:40vh;
  width:40%;
  display:inline-block !important;
}
.firstimglancon {
  position: relative;
  height:0;
  width:100%;
  display:inline-block !important;
}
.firstimg {
  transform: translateX(-800px) rotate(-15deg);
  opacity: 0;
}

.secimg {
  transform: translateX(800px) rotate(15deg);
  opacity: 0;
}


.w4start {
  animation: fullwhite 2s forwards;
  display: none !important;
}

.endshow {
  animation: fullwhite 2s forwards 2s;
  display: inherit !important;
  opacity: 0;
}
.upopacity {
  animation: fullwhite .5s forwards;
  opacity: 1 !important;
}
.upopacitykey {
  animation: fullwhitekey 1s forwards 1.3s;
}

@keyframes fullwhitekey {
  0% {
  }
  100% {
    opacity: .9;
  }

}

[v-cloak] > * { display:none }
[v-cloak]::before { content: "Loading…" }


.upline {
  width: 0;
 height: 1px;
 background: #dab441;
 position: absolute;
 left: calc(100% - 13px);
 top: 0;

}
.downline {
  width: 0;
 height: 1px;
 background: #da4167;
 position: absolute;
 right: calc(100% - 13px);
 bottom: 0;

}
.downline.aos-animate , .upline.aos-animate {
  opacity:.4 !important;
}

@media only screen
  and (min-device-width: 10px)
  and (max-device-width: 736px) {


.startherebox {

     width: 100%;
    line-height: 1.5em;
    font-size: 4vw;

}
.startherebox a {
    font-size: 4vw;
}
.startherebox a:nth-child(1) {
    font-size: 5vw;
}
.butts {
	width: 93%;
}
.butts button{
	font-size:3.2vw !important;
}
.butts .green {
	padding: 0 12px !important;
}
p , .justifytext {
  word-spacing: -.1em !important;
}
.v-card__text {
	font-weight: 100 !important;
}
    .pianokeyboard {
      height: 102vh;
    }

    .firstimglan {
      position: relative !important;
      animation: fiimganmob 8s forwards .3s;
      left:50%;
      bottom:0;

      width: 50vw;
    transition: all 3s ease-out;
      transform: translateX(-25vw) translateY(90vh) scale(2.5);
      opacity: 0;
    }
    @keyframes fiimganmob {
      0% {
        transform:  translateX(-25.3vw) translateY(90vh) scale(2.5);
        opacity: 0;
      }
      100% {
        transform: translateX(-25.3vw) translateY(-13vh) scale(1.05);
        opacity: 1;
      }
    }
.image {
  background-size: cover !important;
}



}
</style>
