<template>
    <div class="piano-keyboard">
        <div v-for="noteObject in notes" :key="noteObject.note"
          class="white-note"  :class="[noteObject.pressed ? 'white-note-pressed' : '']"
          :style="{width: whiteNoteWidthSize + '%', background: whiteNoteBackground(noteObject.pressed)}"
          @mousedown="playNoteMouse(noteObject)" @mouseup="removePressedKeyMouse(noteObject)"
          @mouseover="playNoteHover(noteObject)" @mouseleave="removePressedKey(noteObject)" >

          <div v-if="noteObject.blackNote"
            class="black-note"  :class="[noteObject.blackNote.pressed ? 'black-note-pressed' : '']"
            :style="{background: 'linear-gradient(180deg,#e4e4ec 0%, rgba(255,255,255,1) 55%, rgba(0,0,0,1) 100%)'}"
            @mousedown.stop="playNoteMouse(noteObject.blackNote)" @mouseup.stop="removePressedKeyMouse(noteObject.blackNote)"
            @mouseover.stop="playNoteHover(noteObject.blackNote)" @mouseleave.stop="removePressedKey(noteObject.blackNote)" >

            <div class="key-group unselectable">
                <div v-if="showKeys" class="key-input">
                    {{noteObject.blackNote.key}}
                </div>

                <div v-if="showNotes" class="key-text key-text-on-black-note">
                    {{noteObject.blackNote.note}}
                </div>
            </div>
          </div>

          <div class="key-group unselectable">
            <div v-if="showKeys" class="key-input">
                {{noteObject.key}}
            </div>

            <div v-if="showNotes" class="key-text">
                {{noteObject.note}}
            </div>
          </div>

        </div>

        <v-row class="scrolldown">
          <v-col class=" text-center text-h6 w4start comeup" data-aos-anchor="#home" >
            <scrollactive :offset="150" :duration="1300"  class="d-inline">
            <v-btn href="#ubermich" x-large style="background-color: rgba(0,0,0,.8) !important;" class="white--text scrollactive-item overflow-hidden">
              <div class="upanddown">

              <v-icon class="d-block" color="white--text">mdi-arrow-up</v-icon>
               <br><br>
            Scroll
            <br><br>
              <v-icon class="d-block" color="white--text">mdi-arrow-down</v-icon>
            </div>
          </v-btn>

        </scrollactive>
      </v-col>
        </v-row>
    </div>

</template>

<script>
import * as Tone from "tone";

export default {

  data: function () {
    return {

         /* Tone.js Synth instance which help us to play musical notes */
        synth : null,

        /* Helper map for pressed key, (e.g notesIndexesByKey['a'] = 4, we found the note which coresponds to 'a' key at index 4 in notes array) */
        notesIndexesByKey: {},

        /* Array with generated notes of the form
            {
                note: <C4>,
                key: <a>,
                pressed: <false>,
                blackNote: {
                    name: <C#4>,
                    key: <b>,
                    pressed: <false>
                }
            }
            where note is the note name, key is a keyboard key you have to press in order to play that note,
            pressed is telling us if note is pressed or not and blackNote is mandatory only for notes that have 'sharp/flat'
        */
        notes : [],

        /* It's used to generate notes, used in 'generateNotes' method */
        allNotes: ['C', 'D', 'E', 'F', 'G', 'A', 'B'],

        /* As name says */
        whiteNoteWidthSize: 0,

        /* It's used to play note when mouse is pressed and note is hovered */
        isMousePressed: false
    };
  },

  props: {

    allKeys:{
        type: Array,
        default: () => [
  '.'
        ]
    },

    whiteNoteColor: {
        type: String,
        default: "#1eb7eb"
    },

    blackNoteColor: {
        type: String,
        default: "#f9bb2d"
    },

    showKeys: {
        type: Boolean,
        default: false,
    },

    showNotes: {
        type:Boolean,
        default: false
    },

    startOctave: {
        type: Number,
        default: 2
    },

    endOctave: {
        type: Number,
        default: 4
    },

    sustain: {
        type: Boolean,
        default: false
    }
  },

  watch: {
    startOctave(val){
      this.startOctave = val;
      this.regenerate();
    },
    endOctave(val){
      this.endOctave = val;
      this.regenerate();
    },
    allKeys(val){
      this.allKeys = val;
      this.regenerate();
    }
  },

  created() {
    this.synth = new Tone.Sampler({
	urls: {
		"C4": "C4.mp3",
		"D#4": "Ds4.mp3",
		"F#4": "Fs4.mp3",
		"A4": "A4.mp3",
	},
	release: 1,
	baseUrl: "https://tonejs.github.io/audio/salamander/",
}).toDestination();


    this.generateNotes();
    this.generateNotesIndexesByKey();



    window.onmouseup = () => {
       this.isMousePressed = false;
    }
  },

  destroyed() {
    window.removeEventListener('keydown', () => {});
    window.removeEventListener('keyup', () => {});
    window.removeEventListener('onmouseup', () => {});
  },

  methods: {
    playNote(noteObject) {
        if(!noteObject.pressed){
            this.synth.triggerAttackRelease(noteObject.note, this.sustain ? "2n" : "8n");
            noteObject.pressed = true;
        }
    },

    playNoteMouse(noteObject) {
        this.isMousePressed = true;
        this.playNote(noteObject);
    },

    playNoteHover(noteObject) {
        if(this.isMousePressed)
            this.playNote(noteObject);
    },

    removePressedKey(noteObject) {
        noteObject.pressed = false;
    },

    removePressedKeyMouse(noteObject) {
        this.isMousePressed = false
        this.removePressedKey(noteObject);
    },

    whiteNoteBackground: function(pressed){
      return pressed ? this.whiteNoteColor : 'linear-gradient(to bottom, #eee 0%, #fff 100%)';
    },

    blackNoteBackground: function(pressed){
      return pressed ? this.blackNoteColor : 'linear-gradient(45deg, #555, #222)';
    },

    generateNotes: function(){
        let keyIndex = 0;
        let noteIndex = 0;
        this.notes.length = 0;
        for(let octave = this.startOctave; octave <= this.endOctave; octave++) {

            while(noteIndex < this.allNotes.length) {
                const currentNote = this.allNotes[noteIndex];

                let newNote = {
                    note: currentNote + octave,
                    key: this.allKeys[keyIndex++],
                    pressed: false,
                }

                if(currentNote !== "B" && currentNote !== "E") {
                    let blackNote = {
                        note: currentNote + '#' + octave,
                        key: this.allKeys[keyIndex++],
                        pressed: false,
                    }

                    newNote["blackNote"] = blackNote;
                }

                this.notes.push(newNote);

                if(octave === this.endOctave && currentNote === "B")
                    break;

                noteIndex++;
            }

            noteIndex = 0;
        }

        this.whiteNoteWidthSize = 100 / this.notes.length;
    },

    generateNotesIndexesByKey: function() {
        this.notesIndexesByKey = {}
        for(let index = 0; index < this.notes.length; index++){
            this.notesIndexesByKey[this.notes[index].key] = index;

            if(this.notes[index].blackNote != undefined)
                this.notesIndexesByKey[this.notes[index].blackNote.key] = index;
        }
    },

    regenerate: function() {
      this.generateNotes();
      this.generateNotesIndexesByKey();
    },
  },
}
</script>

<style>
.piano-keyboard {
  position: relative;
  height: 100%;
  width: 100%;
   pointer-events: none;
}

.white-note {
  display: flex;
  justify-content: center;
  float: left;
  position: relative;
  cursor: pointer;
  color: black;
  height: 98%;
  border-radius: 0 0 5px 5px;
  border-left: 1px solid #e4e4ec;
  border-right: 1px solid #e4e4ec;
  border-bottom: 2px solid #e4e4ec;

}

.white-note-pressed {
  background: #e4e4ec;
}

.black-note {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  height: 75%;
  width: 65%;
  left: 68%;
  z-index: 1;
  color: white;
  border-radius: 0 0 3px 3px;
  opacity: .7;
}

.black-note-pressed {
  background: #DAB441 !important;
}

.key-group {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.8vw;
  font-size: 1.2vw;
}

.key-text {
  margin-top: 0.8vw;
}

.key-text-on-black-note {
  transform: rotate(-90deg);
  margin: 0.8vw 0;
  margin-top: 1vw;
}

.key-input {
  text-align: center;
  width: 2em;
  color: inherit;
  font-size: 1vw;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.comeup {
  animation: fullwhite .5s forwards 4s;
  opacity: 0;
}
@keyframes fullwhite {
  0% {
    opacity: 0;
    transform: translate3d(0,40px,0)
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }

}

.upanddown {
  animation: upanddown 12s forwards infinite;
  transform: translateY(-35%)
}
@keyframes upanddown {
  0% {
    transform: translateY(-7%)
  }
  5% {
    transform: translateY(-7%)
  }
  20% {
    transform: translateY(40%);
  }
  25% {
    transform: translateY(40%);
  }
  50% {
    transform: translateY(-7%);
  }
  55% {
    transform: translateY(-7%)
  }
  70% {
    transform: translateY(-40%);
  }
  75% {
    transform: translateY(-40%);
  }
  100% {
    transform: translateY(-7%);
  }

}






.scrolldown {
  bottom: 4em;
  right: 50%;
  position: absolute;
  transform: translateX(41.55%);
}


@media only screen
  and (min-device-width: 10px)
  and (max-device-width: 736px) {
    .scrolldown {
      bottom: 5em;
    }

}
</style>
