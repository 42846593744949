import Vue from 'vue'
import App from './App.vue'
import VueScrollactive from 'vue-scrollactive'
import vuetify from './plugins/vuetify';
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import VueKinesis from "vue-kinesis"
//import $ from 'jquery'




require('vue-animate/dist/vue-animate.min.css')
Vue.config.productionTip = false
Vue.use(VueScrollactive)
Vue.use(VueVideoPlayer)
Vue.use(VueKinesis)

function preload_image(im_url) {
  let img = new Image();
  img.src = im_url;
}

if (window.innerWidth < 960) {
    preload_image('/photos/firstimglan.png');
}else {
  preload_image('/photos/firstimg.png');
preload_image('/photos/secimg.png');
preload_image('/photos/cd-cover-back.png');
}




new Vue({
  vuetify,
  render: h => h(App),
  created () {
    AOS.init()
}
}).$mount('#app')


