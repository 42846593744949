<template>
  <h2 class="d-inline textgradient font-weight-bold mb-10 mb-md-5 text-md-h4 text-h4 text-uppercase text-md-start text-center">{{first}}{{sec}}</h2>
</template>

<script>

export default {
 props: [
   'first',
   'sec'

]

};
</script>
